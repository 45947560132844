import { Controller } from "@hotwired/stimulus";
import * as AgGrid from "ag-grid-community";
import * as columns from "../../grid/columns";
import * as datasource from "../../grid/datasource";
import * as gridUtils from "../../grid/utils";
import { ColumnsState } from "grid/columns_state";

import {
  AMOUNT_COL_DEF,
  AMOUNT_FILTER_COL_DEF,
  DATE_FILTER_COL_DEF,
  DATE_FILTER_UNTIL_NOW_COL_DEF,
  DEFAULT_COL_DEF,
  STATUS_COL_DEF,
  TEXT_FILTER_COL_DEF,
} from "../../grid/columns";

interface ReturnedPayment {
  comment: string;
  customer_name: string;
  due_amount: number;
  due_date: string;
  payment_amount: number;
  payment_date: string;
  payment_method: string;
  reference_number: string;
  return_user_id: string;
  status: string;
  status_orig: string;
  transmission_date: string;
}

interface CtrlCallbacks {
  subscribeOnSearchSubmit(callback: () => void): void;
  subscribeOnResetColumnWidths(callback: () => void): void;
}

function buildGrid($grid: HTMLElement, dataCallbacks: datasource.DatasourceCallbacks, ctrlCallbacks: CtrlCallbacks) {
  const { i18nJson, paginationPageSize, paymentMethodsJson, returnCodesJson, statusesJson } = $grid.dataset;
  const returnCodes = JSON.parse(returnCodesJson);

  const colDefs: AgGrid.ColDef[] = [
    { field: "status", ...STATUS_COL_DEF, sortable: true, ...columns.buildEnumFilterColDef(JSON.parse(statusesJson)) },
    { field: "due_date", ...DEFAULT_COL_DEF, ...DATE_FILTER_COL_DEF, sortable: true },
    { field: "account_number", ...DEFAULT_COL_DEF, ...TEXT_FILTER_COL_DEF, sortable: true },
    { field: "due_amount", ...AMOUNT_COL_DEF, ...AMOUNT_FILTER_COL_DEF, sortable: true },
    {
      field: "payment_method",
      ...DEFAULT_COL_DEF,
      sortable: true,
      ...columns.buildEnumColDef(JSON.parse(paymentMethodsJson)),
      ...columns.buildEnumFilterColDef(JSON.parse(paymentMethodsJson)),
    },
    { field: "payment_date", ...DEFAULT_COL_DEF, ...DATE_FILTER_UNTIL_NOW_COL_DEF, sortable: true },
    { field: "payment_amount", ...AMOUNT_COL_DEF, ...AMOUNT_FILTER_COL_DEF, sortable: true },
    { field: "reference_number", ...DEFAULT_COL_DEF, ...TEXT_FILTER_COL_DEF, sortable: true },
    { field: "transmission_date", ...DEFAULT_COL_DEF, ...DATE_FILTER_UNTIL_NOW_COL_DEF, sortable: true, sort: "desc" },
    { field: "customer_name", ...DEFAULT_COL_DEF, ...TEXT_FILTER_COL_DEF, sortable: true },
    { field: "return_user_id", ...DEFAULT_COL_DEF, ...TEXT_FILTER_COL_DEF, sortable: false },
    { field: "comment", ...DEFAULT_COL_DEF, sortable: false },
    {
      field: "return_code",
      ...DEFAULT_COL_DEF,
      tooltipValueGetter: (params) => returnCodes[params.value],
      sortable: true,
      ...columns.buildEnumColDef(returnCodes),
      ...columns.buildEnumFilterColDef(returnCodes),
    },
  ];

  const dataSource = new datasource.Datasource({ limit: paginationPageSize, callbacks: dataCallbacks });

  const options: AgGrid.GridOptions<ReturnedPayment> = {
    ...gridUtils.DEFAULT_GRID_OPTIONS,
    // override defaults
    cacheBlockSize: parseInt(paginationPageSize),
    columnDefs: gridUtils.buildColumnDefs(colDefs, { i18n: JSON.parse(i18nJson) }),
    datasource: dataSource,
    onColumnResized: (event: AgGrid.ColumnResizedEvent) => {
      columnsState.onResize(event);
    },
  };
  const gridApi = AgGrid.createGrid($grid, options);
  dataSource.gridApi = gridApi;
  const columnsState = new ColumnsState(gridApi, "allocations_returned_payments");
  columnsState.initialize();

  ctrlCallbacks.subscribeOnResetColumnWidths(() => {
    columnsState.reset();
  });
  ctrlCallbacks.subscribeOnSearchSubmit(() => gridApi.onFilterChanged());
  return gridApi;
}

export default class extends Controller {
  static targets = ["grid", "searchInput"];

  declare readonly gridTarget: HTMLInputElement;
  declare readonly hasGridTarget: boolean;
  declare readonly hasSearchInputTarget: boolean;

  gridApi: AgGrid.GridApi;

  connect() {
    if (this.hasGridTarget) {
      const $resetGridBtn: HTMLElement = this.element.querySelector(".reset-grid");
      const $searchInput: HTMLInputElement = document.querySelector(".search-input");

      this.gridApi = buildGrid(
        this.gridTarget,
        {
          getSearchInput() {
            return $searchInput.value;
          },
        },
        {
          subscribeOnSearchSubmit(callback: () => void) {
            $searchInput.onkeydown = function (event) {
              if (event.key === "Enter") {
                callback();
              }
            };
          },
          subscribeOnResetColumnWidths(callback: () => void) {
            $resetGridBtn.addEventListener("click", callback);
          },
        },
      );
    }
  }

  disconnect() {
    this.gridApi?.destroy();
  }
}
