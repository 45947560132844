import * as AgGrid from "ag-grid-community";
import * as dom from "../../utils/dom";
import * as events from "../../utils/events";
import * as gridUtils from "../../grid/utils";
import * as columns from "../../grid/columns";

export enum Action {
  CANCEL = "cancel",
  DISCARD = "discard",
  EDIT = "edit",
  PROVISION = "provision",
}

// visibility

const EDIT_ACTIONS = [Action.CANCEL, Action.PROVISION];
const LIST_ACTIONS = [Action.EDIT, Action.DISCARD];

export function show($agCell: HTMLElement, editing: boolean) {
  if ($agCell) {
    transformRowActions($agCell, editing ? LIST_ACTIONS : EDIT_ACTIONS, dom.hideElement);
    transformRowActions($agCell, editing ? EDIT_ACTIONS : LIST_ACTIONS, dom.showElement);
  }
}

export function hide($agCell: HTMLElement) {
  if ($agCell) {
    $agCell.querySelectorAll<HTMLButtonElement>("button").forEach(dom.hideElement);
  }
}

function transformRowActions(
  $agCell: HTMLElement,
  actions: Action[],
  transformFn: (el: HTMLButtonElement) => void,
): void {
  actions.forEach((action) =>
    $agCell.querySelectorAll<HTMLButtonElement>(`button[data-action="${action}"]`).forEach(transformFn),
  );
}

// events

export function onActionCellClicked(params: AgGrid.CellClickedEvent, handler: (action: Action) => void) {
  const $target = params.event.target as HTMLElement;
  if (!$target) {
    return;
  }
  const $button = $target.tagName.toLowerCase() == "button" ? $target : $target.closest("button");

  if ($button) {
    handler($button.dataset["action"] as Action);
  }
}

// handlers

export function handleCancel(params) {
  if (gridUtils.isRowPinned(params)) {
    params.api.setGridOption("pinnedTopRowData", []);
  }
  params.api.stopEditing();
}

export function suppressKeyboardEvent(
  params: AgGrid.SuppressKeyboardEventParams,
  callbacks: {
    handleProvision: (params: AgGrid.SuppressKeyboardEventParams) => void;
    handleEdit: (params: AgGrid.SuppressKeyboardEventParams) => void;
  },
): boolean {
  if (params.context.cutoffTime == "true") {
    return true;
  }

  if (params.event.code == events.KeyboardEvent.ARROW_DOWN || params.event.code == events.KeyboardEvent.ARROW_UP) {
    return false;
  }

  if (params.event.code == events.KeyboardEvent.ENTER) {
    (params.editing ? callbacks.handleProvision : callbacks.handleEdit)(params);
  }

  if (params.event.code == events.KeyboardEvent.ESCAPE && params.editing) {
    handleCancel(params);
  }

  return true;
}

export const GRID_CALLBACKS: AgGrid.GridOptions = {
  onCellMouseOver(params) {
    if (!gridUtils.isEditing(params)) {
      show(gridUtils.actionsCell(params), false);
    }
  },
  onCellMouseOut(params) {
    if (!gridUtils.isEditing(params)) {
      hide(gridUtils.actionsCell(params));
    }
  },
  onRowEditingStopped(params) {
    if (gridUtils.actionsCell(params)) {
      hide(gridUtils.actionsCell(params));
    }
  },
};
