import * as AgGrid from "ag-grid-community";
import * as columns from "grid/columns";
import * as datasource from "grid/datasource";
import * as events from "utils/events";
import * as gridUtils from "grid/utils";
import * as http from "utils/http";
import { ColumnsState } from "grid/columns_state";
import { Controller } from "@hotwired/stimulus";
import { data } from "autoprefixer";

interface MadeBatch {
  amount: number;
  booked_at: string;
  count: number;
  created_at: string;
  number: string;
  payments_path: string;
  reviewer_id: number;
  user_id: number;
}

interface CtrlCallbacks {
  onRowClicked(event: AgGrid.RowClickedEvent<MadeBatch, any>): void;
  subscribeOnResetColumnWidths(callback: () => void): void;
  subscribeOnSearchSubmit(callback: () => void): void;
}

function buildGrid($grid: HTMLElement, dataCallbacks: datasource.DatasourceCallbacks, ctrlCallbacks: CtrlCallbacks) {
  const { indexPath, i18nJson, paginationPageSize } = $grid.dataset;

  const colDefs: AgGrid.ColDef[] = [
    { field: "number", ...columns.DEFAULT_COL_DEF, ...columns.TEXT_FILTER_COL_DEF, sortable: true },
    { field: "user_id", ...columns.DEFAULT_COL_DEF, ...columns.TEXT_FILTER_COL_DEF, sortable: true },
    { field: "reviewer_id", ...columns.DEFAULT_COL_DEF, ...columns.TEXT_FILTER_COL_DEF, sortable: true },
    { field: "count", ...columns.NUMBER_COL_DEF, ...columns.NUMBER_FILTER_COL_DEF, sortable: true },
    { field: "amount", ...columns.AMOUNT_COL_DEF, ...columns.AMOUNT_FILTER_COL_DEF, sortable: true },
    {
      field: "created_at",
      ...columns.DATE_COL_DEF,
      ...columns.DATE_FILTER_UNTIL_NOW_COL_DEF,
      sortable: true,
      sort: "asc",
    },
    { field: "booked_at", ...columns.DATE_COL_DEF, ...columns.DATE_FILTER_UNTIL_NOW_COL_DEF, sortable: true },
  ];

  const dataSource = new datasource.Datasource({ callbacks: dataCallbacks, indexPath, limit: paginationPageSize });

  const options: AgGrid.GridOptions<MadeBatch> = {
    ...gridUtils.DEFAULT_GRID_OPTIONS,
    // override defaults
    defaultColDef: gridUtils.DEFAULT_GRID_OPTIONS.defaultColDef,
    cacheBlockSize: parseInt(paginationPageSize),
    columnDefs: gridUtils.buildColumnDefs(colDefs, { i18n: JSON.parse(i18nJson) }),
    localeText: { ...columns.FILTER_TRANSLATIONS },
    datasource: dataSource,
    // events
    onColumnResized(event) {
      columnsState.onResize(event);
    },
    onRowClicked: ctrlCallbacks.onRowClicked,
  };
  const gridApi = AgGrid.createGrid($grid, options);
  dataSource.gridApi = gridApi;
  const columnsState = new ColumnsState(gridApi, "next_allocations_review_batches");
  columnsState.initialize();

  ctrlCallbacks.subscribeOnResetColumnWidths(() => {
    columnsState.reset();
  });
  ctrlCallbacks.subscribeOnSearchSubmit(() => gridApi.onFilterChanged());
  return gridApi;
}

export default class extends Controller {
  static targets = ["grid", "searchInput"];

  declare readonly gridTarget: HTMLInputElement;
  declare readonly hasGridTarget: boolean;
  declare readonly hasSearchInputTarget: boolean;

  gridApi: AgGrid.GridApi;

  connect() {
    if (this.hasGridTarget) {
      const $paymentsGrid: HTMLFormElement = document.querySelector("#made-batch-payments");
      const $resetGridBtn: HTMLElement = this.element.querySelector(".reset-grid");
      const $searchInput: HTMLInputElement = document.querySelector(".search-input");

      this.gridApi = buildGrid(
        this.gridTarget,
        {
          getSearchInput() {
            return $searchInput.value;
          },
        },
        {
          onRowClicked(event) {
            $paymentsGrid.src = http.buildLocalUrl(event.data.payments_path, {
              [datasource.SEARCH_PARAM]: $searchInput.value,
            });
          },
          subscribeOnSearchSubmit(callback: () => void) {
            events.subscribeOnEnter($searchInput, callback);
          },
          subscribeOnResetColumnWidths(callback: () => void) {
            $resetGridBtn.addEventListener("click", callback);
          },
        },
      );
    }
  }

  disconnect() {
    this.gridApi?.destroy();
  }
}
