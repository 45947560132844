import { Controller } from "@hotwired/stimulus";

export default class extends Controller<HTMLDivElement> {
  static targets = ["toggleButton", "toggleButtonContainer", "openIcon", "closeIcon", "linkText"];

  declare readonly openIconTarget: HTMLImageElement;
  declare readonly closeIconTarget: HTMLImageElement;
  declare readonly linkTextTargets: HTMLSpanElement[];
  declare readonly toggleButtonContainerTarget: HTMLImageElement;

  private isOpen = false;
  private readonly localStorageKey = "sidebarOpen";

  connect(): void {
    this.isOpen = localStorage.getItem(this.localStorageKey) === "true";
    this.updateSidebarState();
  }

  toggle(): void {
    this.isOpen = !this.isOpen;
    localStorage.setItem(this.localStorageKey, String(this.isOpen));
    this.updateSidebarState();
  }

  private updateSidebarState(): void {
    this.element.classList.toggle("w-48", this.isOpen);
    this.element.classList.toggle("w-16", !this.isOpen);

    this.openIconTarget.classList.toggle("hidden", this.isOpen);
    this.closeIconTarget.classList.toggle("hidden", !this.isOpen);

    this.linkTextTargets.forEach((textElement) => {
      textElement.classList.toggle("hidden", !this.isOpen);
    });
    this.toggleButtonContainerTarget.classList.toggle("justify-end", this.isOpen);
    this.toggleButtonContainerTarget.classList.toggle("justify-center", !this.isOpen);
  }
}
