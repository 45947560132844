import { Controller } from "@hotwired/stimulus";
import * as http from "utils/http";
import FormsTextFieldController from "../forms/text_field_controller";

export default class extends Controller {
  static targets = [
    "modalContainer",
    "paymentAmount",
    "accountNumber",
    "submitButton",
    "firstName",
    "lastName",
    "streetAddress",
    "city",
    "state",
    "zipCode",
  ];

  declare readonly submitButtonTarget: HTMLButtonElement;
  declare readonly modalContainerTarget: HTMLElement;
  declare readonly paymentAmountTarget: HTMLInputElement;
  declare readonly accountNumberTarget: HTMLInputElement;
  declare readonly firstNameTarget: HTMLInputElement;
  declare readonly lastNameTarget: HTMLInputElement;
  declare readonly streetAddressTarget: HTMLInputElement;
  declare readonly cityTarget: HTMLInputElement;
  declare readonly stateTarget: HTMLInputElement;
  declare readonly zipCodeTarget: HTMLInputElement;

  connect() {
    this.modalContainerTarget.classList.remove("hidden");
    this.submitButtonTarget.onclick = this.submitManualPayment.bind(this);
  }

  closeModal() {
    this.element.remove();
  }

  handlePayment(event) {
    this.closeModal();
  }

  private async submitManualPayment(event: MouseEvent) {
    event.preventDefault();
    const redirect_url = window.location.href;
    const body = { manual_payment: this.formToParams(), redirect_url: redirect_url };
    const response = await http.httpPost(this.submitButtonTarget.dataset.paymentsManualPaymentPath, body);

    if (response.ok) {
      const data = await response.json();
      window.location.replace(data["redirect_url"]);
    } else {
      const data = await response.json();
      this.displayServerErrors(data.error);
    }
  }
  private displayServerErrors(errors: Record<string, string[]>): void {
    Object.entries(errors).forEach(([field, messages]) => {
      const inputElement = this.fieldToInputMap[field];
      const inputComponent = inputElement.closest('[data-controller="forms--text-field"]');
      const controller = this.application.getControllerForElementAndIdentifier(
        inputComponent,
        "forms--text-field",
      ) as FormsTextFieldController;

      controller.showError(messages[0]);
    });
  }

  private formToParams() {
    const params = {};

    Object.entries(this.fieldToInputMap).forEach(([field, input]) => {
      if (input instanceof HTMLInputElement && input.type === "checkbox") {
        params[field] = input.checked;
      } else {
        params[field] = input.value;
      }
    });

    return params;
  }

  get fieldToInputMap() {
    return {
      payment_amount: this.paymentAmountTarget,
      account_number: this.accountNumberTarget,
      first_name: this.firstNameTarget,
      last_name: this.lastNameTarget,
      street_address: this.streetAddressTarget,
      city: this.cityTarget,
      state: this.stateTarget,
      zip_code: this.zipCodeTarget,
      is_waive_fee: document.querySelector<HTMLInputElement>('[name="waive_convenience_fee"]'),
    } as Record<string, HTMLInputElement | HTMLSelectElement>;
  }
}
