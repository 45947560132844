import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modalContainer"];

  declare readonly modalContainerTarget: HTMLElement;

  connect() {
    document.addEventListener("turbo:frame-load", this.showModal.bind(this));
  }

  disconnect() {
    document.removeEventListener("turbo:frame-load", this.showModal.bind(this));
  }

  showModal(event) {
    if (event.target.id === "modal") {
      this.modalContainerTarget.classList.remove("hidden");
    }
  }

  closeModal(event) {
    this.modalContainerTarget.classList.add("hidden");
  }

  handleDeactivation(event) {
    this.closeModal(event);
  }
}
